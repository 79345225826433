<template>
  <div class="main-content">
    <b-overlay
      :show="showOverlay"
      opacity="0.60"
      rounded="sm"
      variant="white"
      no-wrap
    ></b-overlay>
    <h2 class="mb-4">{{ mode === 'add' ? 'Add Reminder' : 'Edit Reminder'}}</h2>
    <b-row>
      <b-col md="12 0">
        <b-card>
          <!-- <b-form @submit.prevent="submit" novalidate> -->
          <b-row>
            <b-form-group class="col-md-6" label="Subject *" label-for="input-1">
              <b-form-input
                v-model="form.name"
                type="text"
                required
                placeholder="Subject"
                size="lg"
              ></b-form-input>
            </b-form-group>

            <b-form-group class="col-md-6" label="Subject *" label-for="input-1">
              <b-form-textarea
                v-model="form.name"
                type="text"
                required
                placeholder="Subject"
                size="lg"
                rows="3"
              ></b-form-textarea>
            </b-form-group>

            <b-col md="12" class="d-flex justify-content-end">
              <b-button
                class="mt-3"
                type="submit"
                variant="primary"
                @click="submit"
                >Submit</b-button
              >
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  metaInfo: {
    title: "Add Accounts",
  },
  data() {
    return {
      showOverlay: false,
      form: {
        id: "",
        name: ""
      },
      mode: "add"
    };
  },
  computed: {
    categoryId() {
      return this.$route.params.id;
    }
  },
  methods: {
    ...mapActions(["getExpenseCategory", "addExpenseCategories", "updateExpenseCategories"]),
    submit() {
      this.showOverlay = true;
      if (this.categoryId) {
        this.updateExpenseCategories(this.form)
          .then((c) => {
            this.showOverlay = false;
            this.$bvToast.toast("Expense Category updated successfully", {
              title: "Success",
              variant: "success",
              autoHideDelay: 5000,
            });
            this.$router.push("/expense-category");
          })
          .catch((err) => {
            this.showOverlay = false;
            this.$bvToast.toast(err, {
              title: "Error",
              variant: "danger",
              autoHideDelay: 5000,
            });
          });
      } else {
        this.addExpenseCategories(this.form)
          .then((c) => {
            this.showOverlay = false;
            this.$bvToast.toast("Expense Category added successfully", {
              title: "Success",
              variant: "success",
              autoHideDelay: 5000,
            });
            this.$router.push("/expense-category");
          })
          .catch((err) => {
            this.showOverlay = false;
            this.$bvToast.toast(err, {
              title: "Error",
              variant: "danger",
              autoHideDelay: 5000,
            });
          });
      }
    },
  },
  async mounted() {
    if (this.categoryId) {
      this.mode = "edit"
      const currentCategory = await this.getExpenseCategory(this.categoryId);
      this.form = {...this.form, ...currentCategory}
    }
  },
};
</script>

<style scoped lang="scss">
::v-deep .overflow-scroll {
  overflow: scroll !important;
}
::v-deep .overflow-visible {
  overflow: visible !important;
}
.br-5 {
  border-radius: 5px;
}
.form-group {
  margin-top: 10px;
}
.form-group label {
  font-size: 14px !important;
}
.form-control {
  background: #ffffff;
}
::v-deep.multi-select.select {
  width: 100%;
  display: block !important;
  .btn-select {
    width: 100%;
    text-align: left;
    .buttonLabel {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
::v-deep .select2-selection {
  border: 1px solid #c6c6c6;
  height: calc(2.52438rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.01625rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
::v-deep .select2-selection__rendered {
  padding: 0px !important;
  font-size: 1.01625rem;
  line-height: 1.5;
}
::v-deep
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 100% !important;
  position: absolute;
  top: 0 !important;
  right: 1px;
  width: 20px;
  bottom: 0;
}

::v-deep .vs__dropdown-toggle {
  height: calc(2.52438rem + 2px);
}

::v-deep .vs__open-indicator {
  transform: scale(0.5) !important;
}
::v-deep .vgt-responsive {
  overflow-x: visible !important;
}
::v-deep .table {
  background-color: transparent !important;
}
// ::v-deep table {
//   border-collapse: collapse;
//   border-spacing: 0;
//   width: 100%;
//   table-layout: fixed;
// }

// ::v-deep table td {
//   text-align: left;
//   padding: 8px;
//   width: 200px;
//   border: 1px solid blue;
// }
// ::v-deep .vs__dropdown-menu {
//     position: fixed;
//     width:inherit;
// }
</style>
